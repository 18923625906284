import React, { useLayoutEffect, useState } from 'react'
import SEO from "../components/seo"
import Layout from "../components/layout"
import styled from "styled-components";
import {LeftSection, RightSection, PageWrapper, Row, Span, Title} from '../components/UI'
import { breakpoints, apiURL, apiResponseStatus } from '../utils/helper'
import axios from 'axios'
import _ from 'lodash'
import {navigate} from 'gatsby'
import PageHeader from '../components/PageHeader'
import pattern from '../images/pattern-franchise.jpg'
//import Button from '../components/Button'

const MyRow = styled(Row)`
    padding: 30px 30px;
    
    @media ${breakpoints.desktop} {
        padding: 0 0 90px;
        width: 100%;
        max-width: 850px;
        margin: 0 auto;
    
        &.head {
            padding: 0px 0 42px !important;
        }
    } 

    &.head {
        color: ${props => props.theme.colors.red};
    }
`

const CustomTitle = styled(Title)`
    color: ${props => props.theme.colors.white};
    
    b {
        color: ${props => props.theme.colors.red};    
    }
`

const ContactPage = ({location}) => {
    const [ statusCode, setStatusCode ] = useState(null)
    const query = new URLSearchParams(_.get(location, 'search'))

    useLayoutEffect(() => {
        const email = query.get('email')
        const token = query.get('token')

        if (!email || !token) {
            navigate('/')
        }

        async function unsubscribe() {
            try {
                const res = await axios.post(
                    apiURL('/unsubscribe'),
                    { email, token })
                setStatusCode(_.get(res, 'status'))
            } catch (res) {
                const status = apiResponseStatus(res)
                setStatusCode(status)

                if (status >= 400) {
                    navigate('/')
                }
            }
        }
        unsubscribe()
        // eslint-disable-next-line
    }, [])

    return (
        <Layout location={location}>
            { (!_.isNull(statusCode) && statusCode <= 299) &&
            <>
                <SEO title="Uitschrijven | Je bent nu uitgeschreven voor onze nieuwsbrief"
                     description="Je hebt je succesvol uitgeschreven voor onze nieuwsbrief. Mocht je toch weer op de hoogte willen blijven van onze acties en kortingen, dan kun je je gemakkelijk weer aanmelden."/>
                <PageHeader backgroundColor="#333333" textColor="#FFFFFF" imageWidth="520px" pattern={pattern}>
                    <CustomTitle>
                        Jammer dat je weggaat!
                    </CustomTitle>
                </PageHeader>

                <PageWrapper>
                    <MyRow>
                        <span>
                            Je hebt je succesvol uitgeschreven voor onze nieuwsbrief. Mocht je toch weer op de hoogte willen blijven van onze acties en kortingen, dan kun je je gemakkelijk weer aanmelden.
                        </span>
                    </MyRow>
                </PageWrapper>
            </>}
        </Layout>
    )
}

export default ContactPage
